import apiempresaweb from '@/services/api-recebimentoweb';

export default {
  namespaced: true,

  state: {
    list: [],
  },

  getters: {
    list: (state) => state.list,
  },

  // NUNCA ALTERAR state DIRETAMENTE EM ACTIONS
  actions: {
    async find(_, {
      where, ...params
    } = {}) {
      const [{ data }] = await Promise.all([
        apiempresaweb.get('empresa', { params: { ...where, ...params } }),
      ]);

      return data;
    },

    // Request para validar a imagem e receber a imagem da API
    async findImg() {
      const validate = (await apiempresaweb.get('/empresa/logomarca')).data;
      if (validate[1] === undefined) {
        console.log('Logomarca da empresa não foi encontrada, alterando para a padrão.');
        const data = null;
        return data;
      }
      const apiString = (await apiempresaweb.get()).config.baseURL;
      // Imagens com o mesmo tipo estavam tendo conflitos no cache.
      // ${Date.now()} atribui um valor único à URL que força o cache ser mudado.
      const data = `${apiString}/empresa/logomarca/${validate[1]}?${Date.now()}`;
      return data;
    },

    async postImg(_, base64) {
      console.log(base64);
      const data = (await apiempresaweb.post('/empresa/logomarca', base64));
      return data;
    },

    async updateByWebId(_, payload) {
      console.log(payload);
      const data = await apiempresaweb.put(`/empresa/${payload.id}`, payload);
      return data;
    },
  },

  // APENAS O MUTATION FAZ ALTERAÇÕES NO STATE
  mutations: {
    SET_LIST(state, list) {
      state.list = list ?? [];
    },
  },
};
