import Vue from 'vue';
import VueTheMask from 'vue-the-mask';
import App from './App.vue';

import './registerServiceWorker';

import router from './router';
import store from './store';

import i18n, { setupLanguage } from './plugins/i18n';
import vuetify from './plugins/vuetify';
import './plugins/vue-virtual-scroller';
import './plugins/vue-flip-counter';

import { setupBaseURL, setupApiOnResponseError } from './services/api-recebimentoweb';
import { ROUTE_SIGN_IN } from './constants/ROUTES';

Vue.config.productionTip = false;

Vue.use(VueTheMask);

Promise.all([
  setupLanguage(Array.from(navigator.languages)),
  setupBaseURL(),
  setupApiOnResponseError(async (err) => {
    if (err.response?.status === 401 && store.getters['auth/accessToken']) {
      await store.dispatch('auth/unauthenticate');

      if (router.currentRoute.path === ROUTE_SIGN_IN.path) return;

      router.push({
        path: ROUTE_SIGN_IN.name,
        query: { origin: router.currentRoute.fullPath },
      });
    }

    throw err;
  }),

])
  .then(() => {
    store.dispatch('$_setup');
  })
  .then(() => {
    new Vue({
      router,
      store,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  });
