/* eslint-disable prefer-template */

import { ROUTE_HOME, ROUTE_SIGN_IN } from '@/constants/ROUTES';
import store from '@/store';

export function layoutRoute(path, layout, children) {
  return {
    path,
    children,
    component: () => import(
      /* webpackChunkName: "layouts-[request]" */
      '@/layouts/' + layout + '/Index'
    ),
  };
}

export function viewRoute({
  path = '', name, view, meta, ...components
}, beforeEnter) {
  return {
    path,
    name,
    meta,
    beforeEnter,
    components: {
      default: () => import(
        /* webpackChunkName: "views-[request]" */
        '@/views/' + view + '/Index'
      ),
      ...Object.entries(components ?? {}).reduce((result, [key, value]) => ({
        ...result,
        [key]: () => import(
          /* webpackChunkName: "views-[request]" */
          '@/views/' + view + '/' + value
        ),
      }), {}),
    },
  };
}

export function redirectRoute(redirect) {
  return {
    path: '*',
    redirect,
  };
}

export function requireAuthenticated(to, from, next) {
  if (store.getters['auth/accessToken']) {
    next();
  } else {
    next({
      name: ROUTE_SIGN_IN.name,
      query: { origin: from.fullPath },
    });
  }
}

export function requireUnauthenticated(to, from, next) {
  if (!store.getters['auth/accessToken']) {
    next();
  } else {
    next(ROUTE_HOME.path);
  }
}
