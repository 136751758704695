import * as STORAGE from '@/constants/STORAGE';

import { apiSetToken } from '@/services/api-recebimentoweb';

import storage from '@/services/storage';

export default {
  namespaced: true,

  state: {
    accessToken: null,
    user: null,
  },

  getters: {
    accessToken: (state) => state.accessToken,
    user: (state) => state.user,
  },

  actions: {
    async $_setup({ dispatch, commit }) {
      const accessToken = await storage.getItem(STORAGE.TOKEN);
      if (!accessToken) return;

      if (new Date() > new Date(accessToken.expires)) {
        await storage.removeItem(STORAGE.TOKEN);
        return;
      }

      commit('SET_TOKEN', accessToken);

      await new Promise((resolve) => setTimeout(resolve, 100));
      await dispatch('loadUser');
    },

    async authenticate({ commit, dispatch }, payload) {
      const accessToken = await dispatch('users/login', payload, { root: true });

      await storage.setItem(STORAGE.TOKEN, accessToken);
      commit('SET_TOKEN', accessToken);

      await new Promise((resolve) => setTimeout(resolve, 100));
      await dispatch('loadUser');
    },

    async unauthenticate({ dispatch, commit }) {
      dispatch('users/logout', null, { root: true })
        .catch();

      await storage.removeItem(STORAGE.TOKEN);
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
    },

    async loadUser({ dispatch, getters, commit }) {
      const user = await dispatch('users/findById', {
        id: getters.accessToken.userId,
      }, { root: true });

      commit('SET_USER', user);
    },

    // unauthenticate customizado
    async logout({ commit }) {
      await storage.removeItem(STORAGE.TOKEN);
      commit('SET_TOKEN', null);
      commit('SET_USER', null);
    },

  },

  mutations: {
    SET_TOKEN(state, accessToken) {
      state.accessToken = accessToken;
      apiSetToken(accessToken?.token);
    },

    SET_USER(state, user) {
      state.user = user;
    },
  },
};
